import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// react
import { createContext, useCallback, useEffect, useReducer } from "react";
// utils
import axios from "../utils/axios";
import { setSession } from "../utils/jwt";
import { USER_GLOBAL_HOST_API_KEY } from "../config";
import { getUserData } from "src/services/register/getUserData";
import { getAllOrganizationsOfUser } from "src/services/register/getAllOrganizationsOfUser";
import { PATH_AUTH } from "src/routes/paths";
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  organizations: null,
  currentOrganization: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, organizations, currentOrganization } =
      action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      organizations,
      currentOrganization,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  UPDATE_ORGANIZATIONS: (state, action) => {
    const { organizations } = action.payload;

    return {
      ...state,
      organizations,
    };
  },
  UPDATE_CURRENT_ORGANIZATION: (state, action) => {
    const { currentOrganization } = action.payload;

    return {
      ...state,
      currentOrganization,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const initialize = async () => {
    try {
      const _id = localStorage.getItem("_id");
      if (_id) {
        const allOrgs = await getAllOrganizationsOfUser();
        const currentOrganization = JSON.parse(
          localStorage.getItem("currentOrganization")
        );

        const userData = await getUserData(_id);
        setSession(userData, _id);

        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            user: userData,
            organizations: allOrgs,
            currentOrganization,
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
            organizations: null,
            currentOrganization: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: null,
          organizations: null,
          currentOrganization: null,
        },
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const login = async (email, password) => {
    const data = new URLSearchParams();
    data.append("email", email);
    data.append("password", password);
    const response = await axios({
      method: "post",
      url: `${USER_GLOBAL_HOST_API_KEY}/auth/login`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data,
    });
    const user = await response.data;
    setSession(user, user._id);

    navigate(`${PATH_AUTH.google}?cid=${user._id}`);

    // dispatch({
    //   type: "LOGIN",
    //   payload: {
    //     user,
    //   },
    // });
  };

  const logout = async () => {
    await axios({
      method: "get",
      url: `${USER_GLOBAL_HOST_API_KEY}/auth/logout`,
    });
    setSession(null, null);
    localStorage.removeItem("currentOrganization");
    dispatch({ type: "LOGOUT" });
  };

  const manageAccount = async (id) => {
    try {
      localStorage.removeItem("currentOrganization");
      localStorage.removeItem("currentUser");

      const userData = await getUserData(id);
      setSession(userData, id);

      dispatch({
        type: "LOGIN",
        payload: {
          user: userData,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setOrganizations = useCallback(async (data) => {
    dispatch({
      type: "UPDATE_ORGANIZATIONS",
      payload: {
        organizations: data,
      },
    });
  }, []);

  const setCurrentOrganization = useCallback(async (data) => {
    dispatch({
      type: "UPDATE_CURRENT_ORGANIZATION",
      payload: {
        currentOrganization: data,
      },
    });
    localStorage.setItem("currentOrganization", JSON.stringify(data));
  }, []);

  // const register = async (email, password, firstName, lastName) => {
  //   const response = await axios.post("lms_user/api/account/register", {
  //     email,
  //     password,
  //     firstName,
  //     lastName,
  //   });
  //   const { accessToken, user } = response.data;

  //   localStorage.setItem("accessToken", accessToken);

  //   dispatch({
  //     type: "REGISTER",
  //     payload: {
  //       user,
  //     },
  //   });
  // };
  // const register = async (data, domain) => {
  //   try {
  //     const formData = new FormData();
  //     const request_token = localStorage.getItem("requestToken");
  //     formData.append("org_name", data.org_name);
  //     formData.append("user_name", data.user_name);
  //     formData.append("email", data.email);
  //     formData.append("mobile", `${data.country}${data.mobile}`);
  //     formData.append("location", data.location);
  //     formData.append("about", data.about);
  //     formData.append("password", data.password);
  //     formData.append("request_url", domain);
  //     formData.append("request_token", request_token);

  //     const response = await axios({
  //       method: "post",
  //       url: "/lms_user/api/org/signup",
  //       headers: { "Content-Type": "multipart/form-data" },
  //       data: formData,
  //     });
  //     const res = await response.data;
  //     return res;
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  const requestOTP = async (email) => {
    try {
      const partnerId = localStorage.getItem("partnerId");
      const response = await axios({
        method: "post",
        url: `${USER_GLOBAL_HOST_API_KEY}/auth/request-otp`,
        data: { email, partnerId },
      });
      const { email: userEmail } = await response.data;
      return userEmail;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const verifyOTP = async (otp, email) => {
    try {
      // const partnerId = localStorage.getItem("partnerId");
      const response = await axios({
        method: "post",
        url: `${USER_GLOBAL_HOST_API_KEY}/auth/validate-otp`,
        data: { otp, email },
      });
      const { verification_token } = await response.data;
      return verification_token;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const resetPassword = async (password, verification_token, email) => {
    try {
      await axios({
        method: "post",
        url: `${USER_GLOBAL_HOST_API_KEY}/auth/reset-password-otp`,
        data: {
          email,
          password,
          verification_token,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        // register,
        requestOTP,
        verifyOTP,
        resetPassword,
        manageAccount,
        initialize,
        setOrganizations,
        setCurrentOrganization,
        currentOrganization: state.currentOrganization,
        organizations: state.organizations,
        user: state.user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
