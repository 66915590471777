import * as Yup from "yup";
import { useEffect, useMemo, useContext } from "react";
import { useSnackbar } from "notistack";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";
// components
import { FormProvider, RHFTextField } from "src/components/hook-form";
// context
import { AuthContext } from "src/contexts/JWTContext";

// ----------------------------------------------------------------------

export default function SuccessfullyReset({ handleClose }) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ p: 3 }}
    >
      <Typography variant="h5" sx={{ mb: 1, color: "green" }}>
        Password has been reset successfully.
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Please close this popup and login using the updated password.
      </Typography>
      <Button variant="contained" onClick={handleClose} sx={{ mt: 2 }}>
        close
      </Button>
    </Stack>
  );
}
