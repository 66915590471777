// scroll bar
import "simplebar/src/simplebar.css";
// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

import React, { useState, useEffect, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// contexts
import { SettingsProvider } from "./contexts/SettingsContext";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
import { AuthProvider } from "./contexts/JWTContext";
//
import App from "./App";
import LoadingScreen from "./components/LoadingScreen";
import { getPartnerDetailsFromDomain } from "./services/register/getPartnerDetailsFromDomain";

// ----------------------------------------------------------------------

const Index = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Function to fetch whitelabel config data
    const fetchWhitelabelConfigData = async () => {
      try {
        const url = new URL(window.location.href);
        const hostname = url.hostname.replace(/^www\./, "");
        const response = await getPartnerDetailsFromDomain(hostname);

        const partnerAssets = response || {};
        const { assets, metadata, _id } = partnerAssets;
        localStorage.setItem("partnerId", _id);
        localStorage.setItem("landingLogo", assets.logo);
        localStorage.setItem("faviconLogo", assets.icon);
        localStorage.setItem("displayName", metadata.display_name);
        localStorage.setItem("metaTitle", metadata.display_name);
        localStorage.setItem("metaDiscription", metadata.description);
        localStorage.setItem("metaKeywords", metadata.keywords);
        localStorage.setItem(
          "primaryColor",
          metadata.primary_color || "#2065D1"
        );
        localStorage.setItem(
          "secondaryColor",
          metadata.secondry_color || "#3366FF"
        );
        localStorage.setItem("crmWebhook", metadata.crm_webhook);
        // localStorage.setItem("requestToken", request_token);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchWhitelabelConfigData();
  }, []);

  // Loader to show till the whitelabel data is fetched
  if (loading) {
    return <Suspense fallback={<LoadingScreen isDashboard />} />;
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <HelmetProvider>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <App />
            </CollapseDrawerProvider>
          </SettingsProvider>
        </HelmetProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

// ----------------------------------------------------------------------

ReactDOM.render(<Index />, document.getElementById("root"));
