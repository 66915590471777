import PropTypes from "prop-types";
import * as Yup from "yup";
import { useMemo, useContext } from "react";
import { useSnackbar } from "notistack";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
// routes
// import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import { FormProvider, RHFTextField } from "src/components/hook-form";
// context
import { AuthContext } from "src/contexts/JWTContext";

// ----------------------------------------------------------------------

ResetPasswordForm.propTypes = {
  token: PropTypes.string,
  userEmail: PropTypes.string,
  handleRestsetOtp: PropTypes.func,
};

export default function ResetPasswordForm({
  token,
  userEmail,
  handleRestsetOtp,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { resetPassword } = useContext(AuthContext);

  const ResetPasswordFormSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must have minimum eight characters, at least one letter one number and one special character."
      ),
    confirmPassword: Yup.string().required("Confirm password"),
  });

  const defaultValues = useMemo(
    () => ({
      password: "",
      confirmPassword: "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(ResetPasswordFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleChangePassword = async (data) => {
    try {
      await resetPassword(data.password, token, userEmail);
      enqueueSnackbar("Password reset successfully!");
      handleRestsetOtp();
    } catch (error) {
      enqueueSnackbar(`Unable to reset password! ${error.message}`, {
        variant: "error",
      });
    }
  };

  const onSubmit = async (data) => {
    data.password === data.confirmPassword
      ? await handleChangePassword(data)
      : enqueueSnackbar("Password and Confirm password don't match", {
          variant: "error",
        });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 3 }}>
        <Typography sx={{ mb: 3 }} variant="h5">
          Change Password
        </Typography>
        <Box
          sx={{
            display: "grid",
            columnGap: 2,
            rowGap: 3,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
            },
          }}
        >
          <RHFTextField name="password" label="New password" type="password" />
          <RHFTextField
            name="confirmPassword"
            label="Confirm password"
            type="password"
          />
        </Box>
        <Stack alignItems="flex-start" sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Reset password
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
