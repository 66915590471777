import * as Yup from "yup";
import { useEffect, useMemo, useContext } from "react";
import { useSnackbar } from "notistack";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import { Box, Stack } from "@mui/material";
// components
import { FormProvider, RHFTextField } from "src/components/hook-form";
// context
import { AuthContext } from "src/contexts/JWTContext";

// ----------------------------------------------------------------------

export default function ForgotPwdOtpForm({ requestStatus, setUserEmail }) {
  const { requestOTP } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const ForgotPwdOtpFormSchema = Yup.object().shape({
    email: Yup.string().required("Email Address is required"),
  });

  const defaultValues = useMemo(
    () => ({
      email: "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(ForgotPwdOtpFormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    // setError,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRequestOTP = async (data) => {
    try {
      const userEmail = await requestOTP(data.email);
      setUserEmail(userEmail);
      requestStatus();
      enqueueSnackbar("OTP sent successfully!");
      reset();
    } catch (error) {
      // showError(JSON.parse(error.message));
      enqueueSnackbar(`Failed to send OTP \n ${error.message}`, {
        variant: "error",
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onRequestOTP)}>
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "grid",
            columnGap: 2,
            rowGap: 3,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
            },
          }}
        ></Box>
        <Box sx={{ mb: 2 }}>
          <p>Enter registered EmailId</p>
          <small>
            A OTP will be sent to your registered mobile on whatsapp
          </small>
        </Box>
        <Stack alignItems="flex-start">
          <RHFTextField name="email" label="Email Addrass" />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 2 }}
          >
            Request OTP
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
