import { useEffect, useState } from "react";

// @mui
import { styled } from "@mui/material/styles";
import { Box, Card, Stack, Container, Typography } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Page from "../../components/Page";
import Logo from "../../components/Logo";
import Image from "../../components/Image";
// sections
import { LoginForm } from "../../sections/auth/login";
import signIn from "src/assets/images/signIn.webp";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive("up", "md");

  const storedDisplayName = localStorage.getItem("displayName");
  const storedLogo = localStorage.getItem("landingLogo");

  const [displayName, setDisplayName] = useState(storedDisplayName);

  const [landingLogo, setLandingLogo] = useState(storedLogo);

  useEffect(() => {
    if (storedDisplayName && storedDisplayName !== "null") {
      setDisplayName(storedDisplayName);
    }

    if (storedLogo && storedLogo !== "null") {
      setLandingLogo(storedLogo);
    }
  }, [storedDisplayName, storedLogo]);

  return (
    <Page title="Login">
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={4}
        sx={{
          width: "100vw",
          minHeight: { xs: "100vh", sm: "100vh" }, // Ensure the container has enough height
          overflow: "hidden", // Hide any potential overflow if necessary
          marginTop: { xs: "0", sm: "0" }, // Adjust margin as needed
        }}
      >
        <Logo
          landingLogo={landingLogo}
          sx={{
            display: {
              xs: "block",
              sm: "none",
            },
          }}
        />
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Card
            sx={{
              P: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "80%",
              position: "relative",
              boxShadow: { xs: 0, sm: 3 }, // Shadow only in mobile view (xs)
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              sx={{ width: { xs: "100vw", sm: "100vw" } }}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  maxWidth: "50%",
                  pt: 2,
                  pb: 2,
                  pl: 2,
                  display: { xs: "none", sm: "block" },
                }}
              >
                {/* <SignupImage /> */}
                <img src={signIn} alt="signup" />
              </Stack>

              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  // maxWidth: "80%",
                  width: "100%",
                }}
              >
                <Stack
                  // direction="row"
                  // alignItems="flex-end"
                  // justifyContent="flex-end"
                  sx={{
                    position: "absolute",
                    top: "30px",
                    right: "40px",
                  }}
                >
                  <Logo
                    landingLogo={landingLogo}
                    sx={{ display: { xs: "none", sm: "block" } }}
                  />
                </Stack>
                <LoginForm />
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Page>
  );
}
