import * as Yup from "yup";
import { useEffect, useMemo, useContext } from "react";
import { useSnackbar } from "notistack";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import { Box, Stack } from "@mui/material";
// components
import { FormProvider, RHFTextField } from "src/components/hook-form";
// context
import { AuthContext } from "src/contexts/JWTContext";

// ----------------------------------------------------------------------

export default function VerifyOtpForm({ otpStatus, userEmail }) {
  const { enqueueSnackbar } = useSnackbar();

  const { verifyOTP } = useContext(AuthContext);

  const VerifyOtpFormSchema = Yup.object().shape({
    otp: Yup.string().required("OTP is required"),
  });

  const defaultValues = useMemo(
    () => ({
      otp: "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(VerifyOtpFormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    // setError,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const showError = (err) => {
  //   err.message &&
  //     setError("agentID", {
  //       type: "custom",
  //       message: err.message,
  //     });
  // };

  const onVerifyOtp = async (data) => {
    try {
      const token = await verifyOTP(data.otp, userEmail);
      otpStatus(token);
      enqueueSnackbar("OTP verified successfully!");
      reset();
    } catch (error) {
      // showError(JSON.parse(error.message));
      enqueueSnackbar(`Failed to verify OTP \n ${error.message}`, {
        variant: "error",
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onVerifyOtp)}>
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "grid",
            columnGap: 2,
            rowGap: 3,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
            },
          }}
        ></Box>
        <Box sx={{ mb: 2 }}>
          <p>Enter the 6-digit OTP</p>
          <small>OTP sent to your WhatsApp number</small>
        </Box>
        <Stack alignItems="flex-start">
          <RHFTextField name="otp" label="OTP" type="password" />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 2 }}
          >
            Verify OTP
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
